
import { Component, Vue, Watch, } from 'vue-property-decorator'
import * as Validations from '@/utils/Validations'

import AppVersion from '@/components/AppVersion/index.vue'


import { ResetPasswordInterface, } from '@/store/types/ResetPassword/Index'
import InputHackmetrix from '@/components/InputHackmetrix/Index.vue'

@Component({
  components: {
    AppVersion,
    InputHackmetrix,
  },
})
export default class LoginComponent extends Vue {
  private password = ''
  private showSuccess = false

  public evalidated = false
  public validateLenght = false
  public validateUppercase = false
  public validateLowercase = false
  public validateNumbers = false
  public validatePassword = false

  private hasVisiblePassword = false
  private passwordIcon = 'visibility'
  private valid = false
  private error = false
  private messageInfo = ''
  private reset_password_token: string | (string | null)[] = ''
  private confirmation_token: string | (string | null)[] = ''

  @Watch('password')
  onChildChanged (val: string): void {
    this.validateNumbers = Validations.regexNumber.test(val)
    this.validateLenght = Validations.regexLenght.test(val)
    this.validateUppercase = Validations.regexUppercase.test(val)
    this.validatePassword = Validations.regexPassword.test(val)
    this.validateLowercase = Validations.regexLowercase.test(val)

    if (
      this.password.length >= 8 &&
      this.validateUppercase &&
      this.validateLowercase &&
      this.validateNumbers &&
      this.validatePassword
    ) {
      this.evalidated = true
    } else {
      this.evalidated = false
    }
  }

  mounted () {
    this.reset_password_token = this.$route.query.reset_password_token
    this.confirmation_token = this.$route.query.confirmation_token
  }

  private sendEmail () {
    const resetData: ResetPasswordInterface = {
      password: this.password,
      password_confirmation: this.password,
      reset_password_token: this.reset_password_token,
    }

    this.$store
      .dispatch('AuthModule/ResetPassword', resetData)
      .then(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Éxito, Contraseña reestablecida',
        })
        this.showSuccess = true
      })
      .catch(() => {
        this.$router.push({
          name: 'Recovery-Password',
          query: { message: 'Lo sentimos, el enlace expiró', },
        })
      })
  }

  private confirmAccount () {
    const resetData: ResetPasswordInterface = {
      password: this.password,
      password_confirmation: this.password,
      confirmation_token: this.confirmation_token,
    }

    this.$store
      .dispatch('BusinessModule/confirmUser', resetData)
      .then(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Éxito, Cuenta activada correctamente',
        })
        this.showSuccess = true
      })
      .catch(() => {
        this.$router.push({
          name: 'Recovery-Password',
          query: { message: 'Lo sentimos, el enlace expiró', },
        })
      })
  }

  private goLogin () {
    this.$router.push('/')
  }

  private getPassword (value: string) {
    this.password = value
  }

  private changeIconPass (value: boolean) {
    value ? (this.passwordIcon = 'visibility') : (this.passwordIcon = 'visibility_off')
  }

  private changeBorder (value: boolean) {
    value ? (this.valid = true) : (this.valid = false)
  }

  private validationBars () {
    if (
      this.validateNumbers &&
      this.validateUppercase &&
      this.validateLenght &&
      this.validatePassword
    ) {
      this.error = false
      return 'green-slide'
    }
    if (this.password.length < 1) {
      this.error = false
    }
  }

  private lowValidationBars () {
    if (this.password.length > 0) {
      if (this.validateUppercase && this.validateLowercase) {
        if (this.validatePassword && this.validateNumbers) {
          if (this.validateLenght) {
            return 'green-slide'
          }
          return 'yellow-slide'
        }
        return 'red-slide'
      }
    }
  }

  private middleValidationBars () {
    if (this.password.length > 0) {
      if (this.validateUppercase && this.validateLowercase) {
        if (this.validatePassword && this.validateNumbers) {
          if (this.validateLenght) {
            return 'green-slide'
          }
          return 'yellow-slide'
        }
        return ''
      }
    }
  }
}
